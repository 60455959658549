
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

div {
  display: block;
  box-sizing: inherit;
}
html, body, #root {
  height: 100%;
  width: 100%;
  overflow: hidden;
  margin: 0;
  padding: 0;
  max-width: 100vw;
}
*{
  /* font-family: 'Inter', sans-serif; */
  /* font-family: 'Source Sans Pro', sans-serif; */
}
figure {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 40px;
  margin-inline-end: 40px;
}

@keyframes eMWxiG{
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

.sales-simolify-close-icon-wrapper{
  position: absolute;
  color: var(--btn-color);
  z-index: 2;
  font-size: 20px;
  right: 7px;
  cursor: pointer;
  background: var(--btn-background);
  padding: 0px 5px;
  border-radius: 6px;
  top: 7px;
}
.sales-simolify-close-icon-wrapper:hover{
  background-color: var(--btn-hover-bg);
}



