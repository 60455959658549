.widget-ph1-containerClass {
    width: 100% !important;
    background-color: transparent !important;
  }
  .widget-ph1-inputClass {
    color: #232529 !important;
    font-size: 1rem !important;
    font-family: inherit !important;
    width: 100% !important;
    padding: 10px 15px 10px 80px !important;
    border: 1px solid #e2e8f0 !important;
    border-radius: 0px 3px 3px 0px !important;
    line-height: 1rem !important;
    outline: none !important;
    transition: all 0.3s ease 0s !important;
    box-shadow: 0px 0px 1px 1px #e2e8f094 !important;
    height: 41px !important;

    &:focus {
        border: 1px solid var(--answer) !important;
        box-shadow: 0px 0px 1px 1px var(--answer) !important;
    }
  }
  .widget-ph1-buttonClass {
    border: 1px solid #e2e8f0 !important;
    color: #232529 !important;
    display: flex !important;
    align-items: center !important;
    width: 70px !important;
    padding: 0px 18px !important;
    border-radius: 3px 0px 0px 3px !important;
    // cursor: not-allowed !important;
    // border-right: none !important;
  }
  .widget-ph1-buttonClass:hover {
    background: transparent !important;
  }
  .widget-ph1-dropdownClass {
    background: transparent;
    border-radius: 4px !important;
  }
  .widget-ph1-searchClass {
    background: transparent;
    
  }
  .widget-ph1-searchClass-box {
    width: calc(100% - 20px);
    margin-bottom: 5px;

    color: #232529 !important;
    font-size: 1rem !important;
    font-family: inherit !important;
    // width: 100% !important;
    padding: 10px 15px 10px 15px !important;
    border: 1px solid #e2e8f0 !important;
    border-radius: 0px 3px 3px 0px !important;
    line-height: 1rem !important;
    outline: none !important;
    transition: all 0.3s ease 0s !important;
    box-shadow: 0px 0px 1px 1px #e2e8f094 !important;
    height: 41px !important;
  }