#zxwam-container {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 2147483647;
}

#zxwam-container:after {
  content: "";
  position: absolute;
  opacity: 0;
  width: 300px;
  height: 242px;
  right: -300px;
  bottom: -242px;
  z-index: -1;
  background: url('https://s3.amazonaws.com/erxes/radial06.png');
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: contain;
  transition: all 1s ease;
}

#zxwam-iframe {
  position: absolute !important;
  bottom: 12px;
  right: 12px;
  border: none;
  max-width: none;
  min-height: auto;
  z-index: 2147483647;
}

.zxwam-hidden>iframe {
  width: 72px;
  height: 72px;
  max-width: none;
}

.zxwam-hidden {
  background: none;
}

.zxwam-shown {
  width: 421px;
  height: 100%;
  height: calc(100% - 20px);
  max-height: 780px;
}

.zxwam-shown:after {
  opacity: .9 !important;
  right: -20px !important;
  bottom: -20px !important;
}

.zxwam-shown.small {
  max-height: 310px;
}

.zxwam-shown>iframe,
.sales-simplify-notifier-shown>iframe {
  width: 100% !important;
  height: 100% !important;
  max-width: none;
}

.sales-simplify-notifier-shown {
  /* width: 370px;
  height: 230px; */
  width: 348px;
  height: 170px;
}


.sales-simplify-notifier-shown.fullMessage {
  height: 550px;
  max-height: 100%;
}

@media only screen and (max-width: 420px) {
  #zxwam-container {
    width: 100%;
    max-height: none;
  }

  .zxwam-shown {
    height: 100%;
  }

  #zxwam-iframe {
    bottom: 0;
    right: 0;
  }

  body.messenger-widget-shown.widget-mobile {
    overflow: hidden;
    position: absolute;
    height: 100%;
  }
}