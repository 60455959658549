.zxwa-widget-body {
    font-family: Inter, Noto Sans TC, Noto Sans SC, Helvetica, Arial, sans-serif;
    // margin-bottom: 16px !important;
    display: grid;
    // height: 480px !important;
    // height: 480px !important;
    grid-template-rows: 81px minmax(0, 1fr);
    overflow: hidden;
    border-radius: 12px !important;
    background-color: #fff !important;
    box-shadow: 0 24px 50px 10px rgba(0, 102, 255, 0.07);
    max-width: 390px;
    .zxwa-widget-body-inner {
        padding: 16px 24px;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        background-color: #008070 !important;
        .zxwa-widget-brand-image {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            background-color: #fff !important;
            // padding: 4px;
            // margin-right: 20px;
        }
    }
    .zxwa-chat-wrapper {
        flex-direction: column;
        position: relative;
        display: flex;
        height: 100%;
        width: 100%;
        background-color: #fff !important;
        padding: 24px;
    }
    .zxwa-text-display {
        display: flex;
        flex-direction: column;
        position: relative;
        background-color: #fff !important;
        padding: 20px;
        // height: 100%;
        height: 324px;
        overflow-y: auto;
        justify-content: space-between;
        background-image: url("../../../whatsapp.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        gap: 20px;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }

        .zxwa-chat-bubble-wrapper {
            display: flex;
            height: max-content;
            box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
                rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
            max-height: 100%;
            max-width: 90%;
            flex-direction: column;
            border-radius: 12px;
            background-color: #fff !important;
            padding: 12px 18px;
            word-break: break-all;
        }
        .zxwa-chat-bubble-wrapper2 {
            display: flex;
            box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
                rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
            flex-direction: column;
            align-items: center;
            gap: 20px;
            height: 100%;
            width: 70%;
            padding: 20px 0 0 0;
            margin: 0 40px 0 40px;
            overflow: auto;
            border-radius: 12px;
            background-color: #fff !important;
        }
    }
    &::-webkit-scrollbar {
        display: none;
    }
    .zxwa-start-chat-section {
        z-index: 50;
        display: flex;
        width: 100%;
        background-color: #fff !important;
        padding: 0 20px 20px 20px;
        flex-direction: column;
        a {
            text-decoration: none;
        }
        .start-chat-button {
            cursor: pointer;
            justify-content: center;
            border-width: 0;
            display: flex;
            height: 48px;
            width: 100%;
            align-items: center;
            align-self: end;
            border-radius: 500px !important;
            .start-chat-button-inner {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                .start-chat-label {
                    color: #fff;
                    font-size: 16px !important;
                    padding-left: 8px;
                    margin: 0;
                }
                .font-header {
                    font-family: Matter, ui-sans-serif, system-ui, -apple-system,
                        BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue,
                        Arial, Noto Sans, sans-serif, Apple Color Emoji,
                        Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
                }
            }
        }
        .zxwa-powered-by-label {
            margin-bottom: 0;
            text-align: center;
            font-size: 14px !important;
            .zxwa-zixflow-label {
                font-size: 14px !important;
                color: #4a9ce8 !important;
            }
        }
    }
}
