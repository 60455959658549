.widget-ph-containerClass {
  width: 100%;
  background-color: transparent !important;
}
.widget-ph-inputClass {
  background: transparent !important;
  color: var(--answer) !important;
  font-size: 16px;
  font-family: inherit;
  display: flex;
  min-height: 50px !important;
  width: 100% !important;
  padding: 0px 12px;
  box-shadow: var(--answer) 0px 0px 0px 1px inset;
  border: none;
  border-radius: 7px;
  line-height: 20px;
  outline: none;
  transition: all 0.3s ease 0s;
  appearance: none;
  height: 100% !important;
}
.widget-ph-inputClass:focus {
  box-shadow: var(--answer) 0px 0px 0px 1px inset !important;
  border-color: var(--answer) !important;
}
.widget-ph-buttonClass {
  background: transparent !important;
  box-shadow: var(--answer) 0px 0px 0px 1px inset;
}
.widget-ph-buttonClass:hover {
  background: transparent !important;
}
.widget-ph-dropdownClass {
  background: transparent;
}
.widget-ph-searchClass {
  background: transparent;
}
.widget-ph-searchClass-box {
  width: calc(100% - 20px);
}

.widget-dr-toggle {
  background: transparent !important;
  color: var(--answer) !important;
  font-size: 16px;
  font-family: inherit;
  display: flex;
  height: 40px;
  width: 100% !important;
  padding: 0px 12px;
  box-shadow: var(--answer) 0px 0px 0px 1px inset;
  border: none;
  border-radius: 7px;
  line-height: 20px;
  outline: none;
  transition: all 0.3s ease 0s;
  appearance: none;
  height: 100% !important;
  text-align: inherit !important;
}
.widget-dr-toggle:focus {
  box-shadow: var(--answer) 0px 0px 0px 1px inset !important;
  border-color: var(--answer) !important;
}

.widget-dr-men1 {
  border-color: var(--answer) !important;
  background-color: var(--background) !important;
}
